import React from 'react';
import { 
  Box, 
  Typography,
  Paper,
  Tabs,
  Tab,
  Alert
} from '@mui/material';
import { colors } from '../../admin/utils/constants';
import { TemplateEditorProvider, useTemplateEditor } from '../../context/TemplateEditorContext';

// Import all the components we extracted
import TestEmailDialog from './dialogs/TestEmailDialog';
import TranslateDialog from './dialogs/TranslateDialog';
import TemplateMetadataForm from './forms/TemplateMetadataForm';
import TemplateActions from './actions/TemplateActions';
import { HtmlEditor } from './editors/HtmlEditor';
import { TextEditor } from './editors/TextEditor';
import NotificationManager from '../common/NotificationManager';

/**
 * Main component that wraps the template editor with the necessary context provider
 */
const TemplateEditor = ({ template, onSave, onPreview }) => {
  // Default template structure with default content field
  const defaultTemplate = {
    name: '', 
    description: '', 
    defaultContent: {
      language: 'en', // Default language is English
      subject: '',
      htmlContent: '',
      textContent: ''
    },
    localizations: {}
  };
  
  // Use template if provided, otherwise use default
  const initialTemplate = template || defaultTemplate;
  
  return (
    <TemplateEditorProvider initialTemplate={initialTemplate} onSave={onSave} onPreview={onPreview}>
      <TemplateEditorContent />
    </TemplateEditorProvider>
  );
};

/**
 * The main content component that uses the context
 */
const TemplateEditorContent = () => {
  const { 
    formData,
    handleChange,
    handleContentChange,
    handleHtmlContentChange,
    handleImageValidation,
    handleSave,
    handleSendTest,
    handleEnhanceTemplate,
    handleTranslate,
    handleLanguageChange,
    currentLanguage,
    availableLanguages,
    languageNames,
    activeTab,
    handleTabChange,
    saving,
    adjusting,
    hasBeenAdjusted,
    translating,
    validating,
    errors,
    imageValidation,
    snackbar,
    handleCloseSnackbar,
    testEmailDialogOpen,
    setTestEmailDialogOpen,
    handleTestEmailSubmit,
    translateDialogOpen,
    setTranslateDialogOpen,
    handleOpenTranslateDialog
  } = useTemplateEditor();

  return (
    <Box>
      {/* Guidance panel for template creation process */}
      <Paper sx={{ p: 2, mb: 3, borderRadius: 2, boxShadow: '0 2px 10px rgba(0,0,0,0.05)', bgcolor: 'primary.50' }}>
        <Typography variant="h6" sx={{ mb: 1, fontSize: '1rem', fontWeight: 500 }}>
          Email Template Creation Guide
        </Typography>
        <Typography variant="body2" sx={{ mb: 1 }}>
          Follow these steps to create or edit an email template:
        </Typography>
        <Box component="ol" sx={{ pl: 2, m: 0 }}>
          <Box component="li" sx={{ mb: 0.5 }}>
            <Typography variant="body2">
              Fill in the template <strong>Name</strong>, <strong>Description</strong>, and <strong>Subject</strong>
            </Typography>
          </Box>
          <Box component="li" sx={{ mb: 0.5 }}>
            <Typography variant="body2">
              Paste or create your HTML content in the editor
            </Typography>
          </Box>
          <Box component="li" sx={{ mb: 0.5 }}>
            <Typography variant="body2">
              For <strong>images</strong>, either add <code>&lt;img&gt;</code> tags directly or upload images using the image tool above the editor
            </Typography>
          </Box>
          <Box component="li" sx={{ mb: 0.5 }}>
            <Typography variant="body2" sx={{ color: 'text.secondary', fontStyle: 'italic' }}>
              <strong>Note:</strong> All image uploads must be completed before saving
            </Typography>
          </Box>
          <Box component="li" sx={{ mb: 0.5 }}>
            <Typography variant="body2">
              Click <strong>Adjust for Email Clients</strong> to optimize for compatibility (recommended)
            </Typography>
          </Box>
          <Box component="li" sx={{ mb: 0.5 }}>
            <Typography variant="body2">
              Click <strong>Send Test Email</strong> to verify appearance
            </Typography>
          </Box>
          <Box component="li" sx={{ mb: 0.5 }}>
            <Typography variant="body2">
              <strong>Save</strong> your template
            </Typography>
          </Box>
          <Box component="li" sx={{ mb: 0.5 }}>
            <Typography variant="body2">
              To add translations, select a new language from the dropdown
            </Typography>
          </Box>
          <Box component="li" sx={{ mb: 0.5 }}>
            <Typography variant="body2">
              For translations, either click <strong>Translate from Default</strong> or manually edit the content
            </Typography>
          </Box>
        </Box>
      </Paper>
      
      {/* Metadata form with name, description, etc. */}
      <TemplateMetadataForm
        formData={formData}
        handleChange={handleChange}
        handleContentChange={handleContentChange}
        errors={errors}
        currentLanguage={currentLanguage}
        handleLanguageChange={handleLanguageChange}
        availableLanguages={availableLanguages}
        languageNames={languageNames}
        handleOpenTranslateDialog={handleOpenTranslateDialog}
        translating={translating}
      />
      
      {/* Content editing tabs */}
      <Paper sx={{ borderRadius: 2, boxShadow: '0 2px 10px rgba(0,0,0,0.05)', mb: 3 }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs 
            value={activeTab} 
            onChange={handleTabChange}
            sx={{ 
              px: 2,
              '& .MuiTab-root': {
                textTransform: 'none',
                fontWeight: 500,
                minWidth: 100
              },
              '& .Mui-selected': {
                color: colors.primary,
              },
              '& .MuiTabs-indicator': {
                backgroundColor: colors.primary,
              }
            }}
          >
            <Tab label="HTML Content" />
            <Tab label="Plain Text Version" />
          </Tabs>
        </Box>
        
        <Box sx={{ p: 3 }}>
          {activeTab === 0 && (
            <Box>
              {console.log("TemplateEditor: Rendering HtmlEditor with", {
                htmlContentLength: formData.defaultContent?.htmlContent?.length,
                adjusting,
                hasBeenAdjusted,
                currentLanguage
              })}
              <HtmlEditor 
                value={formData.defaultContent?.htmlContent || ''} 
                onChange={handleHtmlContentChange}
                onValidationChange={handleImageValidation}
                onEnhanceTemplate={handleEnhanceTemplate}
                adjusting={adjusting}
                isDefaultLanguage={currentLanguage === 'en'}
                hasBeenAdjusted={hasBeenAdjusted}
              />
              {errors.htmlContent && (
                <Typography color="error" variant="body2" sx={{ mt: 1 }}>
                  {errors.htmlContent}
                </Typography>
              )}
            </Box>
          )}
          
          {activeTab === 1 && (
            <TextEditor
              value={formData.defaultContent?.textContent || ''}
              onChange={handleContentChange('textContent')}
            />
          )}
        </Box>
      </Paper>
      
      {/* Display image-related errors */}
      {errors.images && (
        <Alert severity="error" sx={{ mt: 2, mb: 2 }}>
          {errors.images}
        </Alert>
      )}
      
      {/* Action buttons */}
      <TemplateActions
        onSendTest={handleSendTest}
        onSave={handleSave}
        saving={saving}
        validating={validating}
        imageValidation={imageValidation}
      />
      
      {/* Notification system */}
      <NotificationManager
        open={snackbar.open}
        message={snackbar.message}
        severity={snackbar.severity}
        onClose={handleCloseSnackbar}
      />
      
      {/* Test Email Dialog */}
      <TestEmailDialog
        open={testEmailDialogOpen}
        onClose={() => setTestEmailDialogOpen(false)}
        onSend={handleTestEmailSubmit}
      />
      
      {/* Translation Dialog */}
      <TranslateDialog
        open={translateDialogOpen}
        onClose={() => setTranslateDialogOpen(false)}
        onTranslate={handleTranslate}
        existingLanguages={availableLanguages}
      />
    </Box>
  );
};

export default TemplateEditor;