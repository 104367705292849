import React, { createContext, useState, useContext, useCallback, useMemo } from 'react';
import apiService from '../services/api';

// Create context
const TemplateEditorContext = createContext();

/**
 * Provider component that wraps the entire template editor
 * Manages all state and provides methods through context
 */
export const TemplateEditorProvider = ({ children, initialTemplate, onSave, onPreview }) => {
  // Default template structure with defaultContent field
  const defaultTemplate = {
    name: '', 
    description: '', 
    defaultContent: {
      language: 'en', // Default language is English
      subject: '',
      htmlContent: '',
      textContent: ''
    },
    localizations: {}
  };
  
  // Process initialTemplate to ensure defaultContent.language is 'en'
  const processedTemplate = initialTemplate ? {
    ...initialTemplate,
    defaultContent: {
      ...initialTemplate.defaultContent,
      language: 'en' // Force default language to be 'en'
    }
  } : defaultTemplate;
  
  // State for form
  const [formData, setFormData] = useState(processedTemplate);
  const [activeTab, setActiveTab] = useState(0);
  const [saving, setSaving] = useState(false);
  const [adjusting, setAdjusting] = useState(false);
  const [hasBeenAdjusted, setHasBeenAdjusted] = useState(false); // Track if template has been adjusted
  const [translating, setTranslating] = useState(false);
  const [validating] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const [errors, setErrors] = useState({});
  const [imageValidation, setImageValidation] = useState({ valid: true, pendingUploads: false, hasErrors: false });
  
  // Dialog states
  const [testEmailDialogOpen, setTestEmailDialogOpen] = useState(false);
  const [translateDialogOpen, setTranslateDialogOpen] = useState(false);
  
  // State for translations and localizations
  // Always use 'en' as the initialLanguage
  const initialLanguage = 'en';
  // Only include languages that the template has been translated to (plus English)
  const [availableLanguages, setAvailableLanguages] = useState([
    initialLanguage, 
    ...Object.keys(initialTemplate?.localizations || {})
  ]); 
  const [currentLanguage, setCurrentLanguage] = useState(initialLanguage);
  
  // Create a translation state object that includes the default content and localizations
  // Always use 'en' as the key for default content
  const initialTranslations = {
    'en': {
      ...initialTemplate?.defaultContent,
      language: 'en' // Ensure language is 'en'
    }
  };
  
  // Add any existing localizations
  if (initialTemplate?.localizations) {
    Object.entries(initialTemplate.localizations).forEach(([lang, content]) => {
      // Skip 'en' in localizations as it should always be in defaultContent
      if (lang !== 'en') {
        initialTranslations[lang] = {
          ...content,
          language: lang // Ensure language matches the key
        };
      }
    });
  }
  
  const [translations, setTranslations] = useState(initialTranslations);
  
  // Language mapping for display
  const languageNames = useMemo(() => ({
    'en': 'English (US)',
    'en-GB': 'English (UK)',
    'en-AU': 'English (Australia)',
    'en-CA': 'English (Canada)',
    'en-NZ': 'English (New Zealand)',
    'en-IE': 'English (Ireland)',
    'en-ZA': 'English (South Africa)',
    'es': 'Spanish (Spain)',
    'es-MX': 'Spanish (Mexico)',
    'es-AR': 'Spanish (Argentina)',
    'es-CO': 'Spanish (Colombia)',
    'es-CL': 'Spanish (Chile)',
    'es-PE': 'Spanish (Peru)',
    'fr': 'French',
    'fr-CA': 'French (Canada)',
    'fr-BE': 'French (Belgium)',
    'fr-CH': 'French (Switzerland)',
    'de': 'German',
    'de-AT': 'German (Austria)',
    'de-CH': 'German (Switzerland)',
    'it': 'Italian',
    'ja': 'Japanese',
    'zh': 'Chinese (Simplified)',
    'zh-TW': 'Chinese (Traditional)',
    'ru': 'Russian',
    'pt': 'Portuguese (Portugal)',
    'pt-BR': 'Portuguese (Brazil)',
    'ar': 'Arabic'
  }), []);
  
  // Handle form changes for top-level fields
  const handleChange = useCallback((field) => (event) => {
    setFormData(prev => ({
      ...prev,
      [field]: event.target.value
    }));
    
    // Clear errors for this field
    if (errors[field]) {
      setErrors(prev => ({
        ...prev,
        [field]: null
      }));
    }
  }, [errors]);
  
  // Handle changes to content fields (subject, htmlContent, textContent)
  const handleContentChange = useCallback((contentField) => (event) => {
    // Extract the new content value
    const newValue = typeof event === 'string' ? event : event.target.value;
    
    console.log(`Context: handleContentChange for ${contentField}`, {
      isStringEvent: typeof event === 'string',
      valueLength: newValue?.length,
      previousLength: formData.defaultContent?.[contentField]?.length,
      isSame: newValue === formData.defaultContent?.[contentField]
    });
    
    // Create a new defaultContent object with the updated field
    const updatedContent = {
      ...formData.defaultContent,
      [contentField]: newValue
    };
    
    setFormData(prev => {
      const updated = {
        ...prev,
        defaultContent: updatedContent
      };
      console.log(`Context: Setting new formData for ${contentField}`, {
        newLength: updated.defaultContent[contentField]?.length,
        prevLength: prev.defaultContent[contentField]?.length
      });
      return updated;
    });
    
    // Clear errors for this field
    if (errors[contentField]) {
      setErrors(prev => ({
        ...prev,
        [contentField]: null
      }));
    }
  }, [formData, errors]);
  
  // Handle direct HTML content changes from the editor
  const handleHtmlContentChange = useCallback((content) => {
    console.log("Context: handleHtmlContentChange called with content length:", content?.length);
    handleContentChange('htmlContent')(content);
  }, [handleContentChange]);
  
  // Handle image validation changes
  const handleImageValidation = useCallback((validationStatus) => {
    setImageValidation(validationStatus);
  }, []);
  
  // Handle tab changes
  const handleTabChange = useCallback((event, newValue) => {
    setActiveTab(newValue);
  }, []);
  
  // Insert variable into content at cursor position
  const handleInsertVariable = useCallback((variable) => {
    const variableText = `{{${variable}}}`;
    
    // For now, just append to the end since we don't have cursor position in the mock
    const updatedContent = {
      ...formData.defaultContent,
      htmlContent: (formData.defaultContent?.htmlContent || '') + variableText
    };
    
    setFormData(prev => ({
      ...prev,
      defaultContent: updatedContent
    }));
  }, [formData]);
  
  // Validate form before saving
  const validateForm = useCallback(() => {
    const newErrors = {};
    
    // Basic field validation
    if (!formData.name) {
      newErrors.name = 'Template name is required';
    }
    
    // Validate defaultContent fields
    if (!formData.defaultContent?.subject) {
      newErrors.subject = 'Subject line is required';
    }
    
    if (!formData.defaultContent?.htmlContent) {
      newErrors.htmlContent = 'HTML content is required';
    }
    
    // Check image validation status
    if (imageValidation.pendingUploads) {
      newErrors.images = 'All images must be uploaded before saving';
      console.log('Form validation failed: pending image uploads', imageValidation);
    }
    
    if (imageValidation.hasErrors) {
      newErrors.images = 'Please fix image upload errors before saving';
      console.log('Form validation failed: image upload errors', imageValidation);
    }
    
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  }, [formData, imageValidation]);
  
  // Handle save
  const handleSave = useCallback(async (isDraft = false) => {
    // Validate images before saving
    const bypassImageValidation = false; // Production setting
    
    if (!bypassImageValidation && !validateForm()) {
      setSnackbar({
        open: true,
        message: 'Please fix the errors before saving',
        severity: 'error'
      });
      return;
    }
    
    try {
      setSaving(true);
      
      // Prepare the data to save, ensuring defaultContent.language is 'en'
      const dataToSave = {
        ...formData,
        defaultContent: {
          ...formData.defaultContent,
          language: 'en' // Always ensure defaultContent.language is 'en'
        },
        isDraft
      };
      
      console.log('Saving template with data:', dataToSave);
      
      if (onSave) {
        // Wait for the save promise to resolve or reject
        const result = await onSave(dataToSave);
        
        // Only show success if save was successful
        if (result && !result.error) {
          setSnackbar({
            open: true,
            message: `Template ${isDraft ? 'saved as draft' : 'published'} successfully`,
            severity: 'success'
          });
        } else if (result && result.error) {
          throw new Error(result.error);
        }
      }
    } catch (error) {
      console.error('Error saving template:', error);
      setSnackbar({
        open: true,
        message: `Error: ${error.message || 'Could not save the template'}`,
        severity: 'error'
      });
    } finally {
      setSaving(false);
    }
  }, [formData, onSave, validateForm]);
  
  // Close snackbar
  const handleCloseSnackbar = useCallback(() => {
    setSnackbar(prev => ({
      ...prev,
      open: false
    }));
  }, []);
  
  // Send test email
  const handleSendTest = useCallback(() => {
    setTestEmailDialogOpen(true);
  }, []);
  
  // Handle test email submission from dialog
  const handleTestEmailSubmit = useCallback(async (email) => {
    try {
      setSaving(true); // Reuse the saving state
      
      // Call API to send test email with the new data structure
      // The API service will handle extracting the necessary fields
      const testData = {
        ...formData,
        receiverEmail: email
      };
      
      const response = await apiService.admin.testEmailTemplate(testData, email);
      
      setSnackbar({
        open: true,
        message: response.message || `Test email sent to ${email}`,
        severity: response.success ? 'success' : 'error'
      });
    } catch (error) {
      console.error('Error sending test email:', error);
      setSnackbar({
        open: true,
        message: `Error sending test: ${error.message || 'Could not send test email'}`,
        severity: 'error'
      });
    } finally {
      setSaving(false);
    }
  }, [formData]);
  
  // Function to handle all language switching operations
  const switchLanguage = useCallback((newLanguage) => {
    // Skip processing if the language is null, undefined, or not a string
    if (!newLanguage || typeof newLanguage !== 'string') {
      console.log('Invalid language value detected:', newLanguage);
      return;
    }
    
    // Save current content to translations
    if (currentLanguage === 'en') {
      setTranslations(prev => ({
        ...prev,
        [currentLanguage]: { ...formData.defaultContent }
      }));
    } else {
      setTranslations(prev => ({
        ...prev,
        [currentLanguage]: { 
          language: currentLanguage,
          subject: formData.defaultContent?.subject || '',
          htmlContent: formData.defaultContent?.htmlContent || '',
          textContent: formData.defaultContent?.textContent || ''
        }
      }));
    }
    
    // Load the selected language content
    if (translations[newLanguage]) {
      // If switching to the default language (en)
      if (newLanguage === 'en') {
        setFormData(prev => ({
          ...prev,
          defaultContent: {
            ...translations[newLanguage],
            language: 'en' // Ensure the language is always en for defaultContent
          }
        }));
      } else {
        // If switching to a localized language
        setFormData(prev => ({
          ...prev,
          defaultContent: {
            language: newLanguage,
            subject: translations[newLanguage].subject || '',
            htmlContent: translations[newLanguage].htmlContent || '',
            textContent: translations[newLanguage].textContent || ''
          }
        }));
      }
    } else {
      // If no translation exists for this language yet, create an empty one
      setFormData(prev => ({
        ...prev,
        defaultContent: {
          language: newLanguage,
          subject: '',
          htmlContent: '',
          textContent: ''
        }
      }));
      
      // Add to translations
      setTranslations(prev => ({
        ...prev,
        [newLanguage]: {
          language: newLanguage,
          subject: '',
          htmlContent: '',
          textContent: ''
        }
      }));
      
      // Add the new language to available languages if it's not already there
      if (typeof newLanguage === 'string' && !availableLanguages.includes(newLanguage)) {
        // When adding a new language, also clean up any invalid values in the array
        setAvailableLanguages(prev => {
          const validLanguages = prev.filter(lang => !!lang && typeof lang === 'string');
          return [...validLanguages, newLanguage];
        });
      }
      
      // If this is a newly added language, show a hint about translation
      setSnackbar({
        open: true,
        message: 'New language added. You can translate content from the default language or edit manually.',
        severity: 'info'
      });
    }
    
    setCurrentLanguage(newLanguage);
    
    // Reset the hasBeenAdjusted flag when switching languages
    setHasBeenAdjusted(false);
  }, [currentLanguage, formData.defaultContent, translations, availableLanguages]);
  
  // Handle language change in the language selector
  const handleLanguageChange = useCallback((event) => {
    const newLanguage = event.target.value;
    
    // Skip processing if the language is null, undefined, or not a string
    if (!newLanguage || typeof newLanguage !== 'string') {
      console.log('Invalid language value detected:', newLanguage);
      return;
    }
    
    // Check if there are unsaved changes - significant changes to watch for
    const hasUnsavedChanges = Boolean(formData.defaultContent?.htmlContent?.trim());
    
    // If we're switching to a non-existent language where content would be lost and we have content
    if (
      hasUnsavedChanges && 
      (!translations[newLanguage] || !translations[newLanguage].htmlContent) &&
      formData.defaultContent?.htmlContent?.trim() !== ''
    ) {
      // Show warning with the option to save first
      const saveFirst = window.confirm(
        'You have unsaved changes that might be lost when switching languages.\n\n' +
        'Would you like to save your changes before switching languages?\n\n' +
        'Click "OK" to save first, or "Cancel" to switch without saving.'
      );
      
      if (saveFirst) {
        // Save current content before switching
        handleSave(false).then(() => {
          // After saving, proceed with language change
          switchLanguage(newLanguage);
        });
        return;
      }
    }
    
    // If no unsaved changes or user chose not to save, proceed with language change
    switchLanguage(newLanguage);
    
  }, [formData.defaultContent, handleSave, switchLanguage, translations]);
  
  // Function to open translation dialog or handle direct translation
  const handleOpenTranslateDialog = (predefinedLanguage = null) => {
    // If a specific language is provided, skip the dialog and translate directly
    if (predefinedLanguage && typeof predefinedLanguage === 'string') {
      handleTranslate(predefinedLanguage);
      return;
    }
    
    // Handle deprecated array-based predefined languages (for backward compatibility)
    if (predefinedLanguage && Array.isArray(predefinedLanguage) && predefinedLanguage.length > 0) {
      handleTranslate(predefinedLanguage[0]);
      return;
    }
    
    // Otherwise open the dialog for selecting a language
    setTranslateDialogOpen(true);
  };
  
  // Handle translation submission for a single language
  const handleTranslate = useCallback(async (targetLanguage) => {
    if (!targetLanguage || typeof targetLanguage !== 'string') {
      setSnackbar({
        open: true,
        message: 'Please select a valid target language',
        severity: 'error'
      });
      return;
    }
    
    try {
      setTranslating(true);
      
      // Get source content for translation
      
      // Get the source language content - always use default language (en) as source
      const sourceLanguage = 'en';
      const sourceContent = translations[sourceLanguage] || formData.defaultContent;
      
      if (!sourceContent || !sourceContent.htmlContent) {
        throw new Error('Source content is missing or empty');
      }
      
      console.log(`Translating from ${sourceLanguage} to ${targetLanguage}`);
      
      // Call the new translation API
      const response = await apiService.admin.translateEmailTemplateContent(
        sourceContent,
        sourceLanguage,
        targetLanguage
      );
      
      if (response && response.success && response.translatedContent) {
        // Update the translations state with the new translated content
        const newTranslations = { ...translations };
        
        // Add the translated content
        newTranslations[targetLanguage] = {
          ...response.translatedContent,
          language: targetLanguage // Ensure language is set correctly
        };
        
        // Update the translations state
        setTranslations(newTranslations);
        
        // If we're currently viewing the target language, update the form data
        if (currentLanguage === targetLanguage) {
          setFormData(prev => ({
            ...prev,
            defaultContent: {
              ...response.translatedContent,
              language: targetLanguage
            }
          }));
        }
        
        // Update localizations in the form data
        const newLocalizations = { ...formData.localizations };
        
        // Add or update the localization for this language
        newLocalizations[targetLanguage] = {
          ...response.translatedContent,
          language: targetLanguage
        };
        
        // Update the formData with new localizations
        setFormData(prev => ({
          ...prev,
          localizations: newLocalizations
        }));
        
        // Make sure the language is in availableLanguages
        if (!availableLanguages.includes(targetLanguage)) {
          setAvailableLanguages(prev => [...prev, targetLanguage]);
        }
        
        setSnackbar({
          open: true,
          message: `Successfully translated template to ${languageNames[targetLanguage] || targetLanguage}`,
          severity: 'success'
        });
      } else if (response && response.error) {
        throw new Error(response.error);
      } else {
        throw new Error('Translation failed with an unknown error');
      }
    } catch (error) {
      console.error('Error translating template:', error);
      setSnackbar({
        open: true,
        message: `Error: ${error.message || 'Could not translate the template'}`,
        severity: 'error'
      });
      
      // Revert any changes
      setFormData(prev => ({
        ...prev,
        defaultContent: prev.defaultContent
      }));
    } finally {
      setTranslating(false);
    }
  }, [availableLanguages, currentLanguage, formData, translations, languageNames]);
  
  // Handle enhance email template
  const handleEnhanceTemplate = useCallback(async () => {
    try {
      setAdjusting(true);
      
      console.log("ENHANCE: Starting enhancement with formData:", JSON.stringify({
        id: formData.id,
        hasDefaultContent: !!formData.defaultContent,
        htmlContentLength: formData.defaultContent?.htmlContent?.length
      }));
      
      // Call API to enhance template
      const response = await apiService.admin.enhanceEmailTemplate(formData);
      
      console.log("ENHANCE: Received API response:", JSON.stringify({
        success: response.success,
        hasContent: !!response.content,
        htmlContentLength: response.content?.htmlContent?.length,
        rootHtmlContentLength: response.htmlContent?.length,
        hasHtmlAtRoot: !!response.htmlContent,
        message: response.message
      }));
      
      if (response && response.success) {
        // Update the HTML content with the enhanced version
        // The server may return the content in either response.content.htmlContent (nested)
        // or directly in response.htmlContent (root level)
        if (response.content?.htmlContent || response.htmlContent) {
          const enhancedHtml = response.content?.htmlContent || response.htmlContent;
          console.log("ENHANCE: Updating formData with new HTML content, length:", enhancedHtml.length);
          
          // Update formData state
          setFormData(prev => {
            const updated = {
              ...prev,
              defaultContent: {
                ...prev.defaultContent,
                htmlContent: enhancedHtml
              }
            };
            console.log("ENHANCE: Updated formData state created, htmlContentLength:", 
              updated.defaultContent.htmlContent.length);
            return updated;
          });
          
          // Mark template as adjusted
          setHasBeenAdjusted(true);
          console.log("ENHANCE: Template marked as adjusted");
        } else {
          console.log("ENHANCE: No content in response to update editor with");
        }
        
        // Display enhanced template optimizations if available
        const optimizationsMessage = response.optimizations && response.optimizations.length > 0
          ? `Template optimized: ${response.optimizations.join(', ')}`
          : 'Template optimized for better email client compatibility';
          
        setSnackbar({
          open: true,
          message: optimizationsMessage,
          severity: 'success'
        });
      } else {
        console.log("ENHANCE: Response unsuccessful or missing success property");
      }
    } catch (error) {
      console.error('Error enhancing template:', error);
      setSnackbar({
        open: true,
        message: `Error: ${error.message || 'Could not enhance the template'}`,
        severity: 'error'
      });
    } finally {
      setAdjusting(false);
      console.log("ENHANCE: Enhancement process completed");
    }
  }, [formData]);
  
  // Create context value with all state and handlers
  const contextValue = {
    // Form data and changes
    formData,
    setFormData,
    handleChange,
    handleContentChange,
    handleHtmlContentChange,
    handleImageValidation,
    
    // Form actions
    handleSave,
    handleInsertVariable,
    validateForm,
    
    // Translation and localization
    translations,
    currentLanguage,
    availableLanguages,
    languageNames,
    handleLanguageChange,
    handleTranslate,
    
    // UI state
    activeTab,
    handleTabChange,
    errors,
    imageValidation,
    saving,
    adjusting,
    hasBeenAdjusted,
    translating,
    validating,
    
    // Notifications
    snackbar,
    handleCloseSnackbar,
    
    // Dialog state and handlers
    testEmailDialogOpen,
    setTestEmailDialogOpen,
    handleSendTest,
    handleTestEmailSubmit,
    translateDialogOpen,
    setTranslateDialogOpen,
    handleOpenTranslateDialog,
    
    // API interactions
    handleEnhanceTemplate
  };
  
  return (
    <TemplateEditorContext.Provider value={contextValue}>
      {children}
    </TemplateEditorContext.Provider>
  );
};

// Custom hook to use the template editor context
export const useTemplateEditor = () => {
  const context = useContext(TemplateEditorContext);
  if (!context) {
    throw new Error('useTemplateEditor must be used within a TemplateEditorProvider');
  }
  return context;
};